import React, { lazy, Suspense, useEffect } from "react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import { createBrowserHistory as createHistory } from "history";
// Components
import QuizStart from "../components/pages/QuizStart/QuizStart";
import LoadingPage from "../components/pages/LoadingPage/LoadingPage";
import QuestionBlock from "../components/pages/QuestionBlock/QuestionBlock";
import Header from "../components/Header";
import { getAllQuestions } from "../actions/questions";

export const history = createHistory();

const QuizResult = lazy(() => import("../components/pages/QuizResult/QuizResult"));
const NotFoundPage = lazy(() => import("../components/pages/NotFoundPage/NotFoundPage"));

class AnimSwitch extends React.Component {
  componentDidMount() {
    this.props.getAllQuestions();
  }
  render() {
    const appTitle = "EcommerceMatch";
    return (
      <TransitionGroup>
        <CSSTransition key={this.props.location.key} classNames="fade" timeout={700}>
          <Switch location={this.props.location}>
            {!!this.props.questions && this.props.questions.map((item) => (
              <Route state={item.id} key={`/questions/${item.id}`} path={`/questions/${item.id}`}>
                {() => (
                  <React.Fragment>
                    <QuestionBlock title={`EcommerceMatch | Domanda ${item.id}`} question={item} />
                  </React.Fragment>
                )}
              </Route>
            ))}
            <Route>
              {this.props.loadingQestions ? (
                <LoadingPage />
              ) :
              (
                <NotFoundPage title={appTitle + " | 404 Non trovato"} />
              )}
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    )
  }
};

const mapStateToProps = (state) => ({
  loadingQestions: state.questions.loadingQestions,
  questions: state.questions.questions
});
const mapDispatchToProps = (dispatch) => ({
  getAllQuestions: () => dispatch(getAllQuestions())
});

const AnimatedSwitch = withRouter(connect(mapStateToProps, mapDispatchToProps)(AnimSwitch));

export default () => {
  // Scroll to top on route change
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);
  const appTitle = "EcommerceMatch";
  return (
    <Router history={history}>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <Route path="/"
            exact={true}
          >
            <QuizStart title={appTitle + " | Fai match con il tuo e-commerce"} />
          </Route>
          <Route path="/questions">
            <React.Fragment>
              <Header hasSteps={true} />
              <AnimatedSwitch />
            </React.Fragment>
          </Route>
          <Route
            path="/result"
            render={props => (
              <QuizResult
                title={appTitle + " | Risultati"}
                {...props}
              />
            )}
          />
          <Route>
            <NotFoundPage title={appTitle + " | 404 Non trovato"} />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};