import axios from "axios";
/*
* returns object with quiz
*/

export async function getQuiz() {
  const quiz = await makeRequest('GET', 'questions/all', null);
  return quiz;
}

/*
* returns object with single question
*/
// export async function getSingleQuestion(id) {
//   const quiz = await makeRequest('GET', 'questions/'+id, null);
//   return quiz;
// }

/*
* returns object with single platform
*/
export async function getSinglePlatforms(code) {
  const quiz = await makeRequest('GET', 'platforms/'+code, null);
  return quiz;
}

/*
* returns object with all platforms
*/
export async function getAllPlatforms(id) {
  const quiz = await makeRequest('GET', 'platforms/all', null);
  return quiz;
}

/*
* makes POST request to the API endpoint requests/insert
* returns object with request id
*/
export async function makeFormRequest(data) {
  const jsonData = JSON.stringify(data);
  const urlencoded = new URLSearchParams();
  urlencoded.append('data', jsonData);
  const request = await makeRequest('POST', 'requests/insert', urlencoded);
  return request;
}

/*
* make request API endpoint request_answers/insert
* returns object with select data
*/
export async function makeAnswersRequest(data) {
  const jsonData = JSON.stringify(data);
  const urlencoded = new URLSearchParams();
  urlencoded.append('data', jsonData);
  const request = await makeRequest('POST', 'request_answers/insert', urlencoded);
  return request;
}

/*
* makes GET/POST request to the API endpoints
* takes method of the API and adds to url request
* returns object
*/
function makeRequest (method, url, data) {
    return axios({
      method,
      url: process.env.REACT_APP_API_URL + url,
      data,
      headers: {
        "Authorization": `Bearer ${process.env.REACT_APP_API_BEARER}`,
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json"
      },
      responseType: 'json'
    }).then(response => {
      if (response.status === 200) {
        return response.data;
      } else {
        return response.statusText;
      }
    }).catch(e => {
      return e;
    });
}