import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import questionsReducer from '../reducers/questions'


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
    const store = createStore(
        combineReducers({
            questions: questionsReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
};
