import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from 'classnames/bind';
import styles from './QuizStart.scss';
import logo from "../../../assets/images/logo.svg";
import { clearState } from "../../../actions/questions";

const cx = classNames.bind(styles);
export class QuizStart extends React.Component {
  componentDidMount() {
    document.title = this.props.title;
    // cleare state
    this.props.clearState();
  }
  render() {
    return (
      <div className={cx('main-page')}>
        <div>
          <img className="logo" src={logo} alt="EcommerceMatch" width="105" height="84" />
          <h1>EcommerceMatch</h1>
          <p>fai match con il tuo e-commerce</p>
          <Link className="custom-btn" to="/questions/1">
            Inizia ora
          </Link>
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  clearState: () => dispatch(clearState())
});

export default connect(undefined, mapDispatchToProps)(QuizStart);