import React from "react";
import classNames from 'classnames/bind';
import styles from './LoadingPage.scss';
import loader from "../../../assets/images/loader-page.svg";

const cx = classNames.bind(styles);

const LoadingPage = () => (
  <div className={cx('loader-page')}>
    <img className="loader" src={loader} alt="loader" />
  </div>
);

export default LoadingPage;
