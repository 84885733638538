import React from "react"; 

export default () => (
  <footer className="footer">
    <div className="poweredby">
      Powered by <a href="https://www.tun2u.it/" target="_blank" rel="noopener noreferrer">Tun2U E-commerce Enabler</a>
    </div>
    <div className="social">
      <a href="https://twitter.com/tun2u" target="_blank" rel="noopener noreferrer">
        <span className="icon-twitter"></span>
      </a>
      <a href="https://www.facebook.com/tun2u" target="_blank" rel="noopener noreferrer">
        <span className="icon-facebook-1"></span>
      </a>
      <a href="https://www.instagram.com/tun2u/" target="_blank" rel="noopener noreferrer">
        <span className="icon-instagram-1"></span>
      </a>
      <a href="https://www.linkedin.com/company/tun2u" target="_blank" rel="noopener noreferrer">
        <span className="icon-linkedin"></span>
      </a>
    </div>
  </footer>
);