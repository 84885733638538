import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TagManager from 'react-gtm-module';
import classNames from 'classnames/bind';
import styles from './QuestionBlock.scss';
import { setUserAnswer, setActiveQuestionId, reinitUserAnswers } from "../../../actions/questions";
import { fromOrDefault } from "../../../services/Storage";
import { USER_ANSWERS_KEY } from "../../../services/Constants";

const cx = classNames.bind(styles);

class QuestionBlock extends React.Component {
  componentDidMount() {
    document.title = this.props.title;
    this.props.setActiveQuestionId(this.props.question.id);
    const userAnswers = fromOrDefault(USER_ANSWERS_KEY, false);
    if (!this.checkPrevAnswers()) {
      this.props.history.push("/");
    }
    if (Object.keys(this.props.userAnswers).length === 0 && !!userAnswers) {
      this.props.reinitUserAnswers(userAnswers);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      if (!this.checkPrevAnswers()) {
        this.props.history.push("/");
      }
      this.props.setActiveQuestionId(this.props.question.id);
      document.title = `${this.props.title} | Domanda ${this.props.question.id}`
    }

  }

  setUserAnswer(item, id) {
    this.props.setUserAnswer(item, id);
    const tagManagerArgs = {
      dataLayer: {
        'event': 'answerClick',
        'question': this.props.question.name,
        'answer': item.name
      }
    };
    TagManager.dataLayer(tagManagerArgs);
    if (this.props.question.id === this.props.questions.length) {
      this.props.history.push("/result/");
    } else {
      this.props.history.push(`/questions/${this.props.question.id + 1}`);
    }
  }

  checkPrevAnswers() {
    const answers = fromOrDefault(USER_ANSWERS_KEY, false);
    const id = this.props.question.id;
    if (id === 1) {
      return true;
    }
    if (!answers || !answers.userAnswers[id - 1]) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <div className="page">
        <div className={cx('question-block')}>
          {this.props.question.name !== 'Error' && this.props.questions.length > 0 ? (
            <div>
              <div className="question">
                <h1 className="question-title">
                  {this.props.question.name}
                </h1>
                <p className="question-text">
                  {this.props.question.description}
                </p>
              </div>
              <div className="answers">
                {!!this.props.question.answers && this.props.question.answers.map((item, i) => (
                  <button className="answer-item"
                    onClick={() => this.setUserAnswer(item, this.props.question.id)}
                    key={i}
                    disabled={this.props.isAnimating}
                  >
                    <div className="item-wrap">
                      <div className="icon-wrap">
                        {!!item.icon && item.icon.length > 0 ? (
                          <i className={"icon " + item.icon}></i>
                        ) : (
                          <i className="icon icon-cart"></i>
                        )}
                      </div>
                      <div className="answer-text">
                        {item.name}
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div className="question">
                <h1 className="question-title">
                  Quiz non caricato. Per favore riprova più tardi
                </h1>
                <br />
                <Link className="custom-btn" to="/">
                  Torna alla home
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  questions: state.questions.questions,
  userAnswers: state.questions.userAnswers,
  totalScore: state.questions.totalScore,
  totalPrice: state.questions.totalPrice,
  isAnimating: state.questions.isAnimating
});

const mapDispatchToProps = (dispatch) => ({
  setUserAnswer: (answer, id) => dispatch(setUserAnswer(answer, id)),
  setActiveQuestionId: (questionId) => dispatch(setActiveQuestionId(questionId)),
  reinitUserAnswers: (answers) => dispatch(reinitUserAnswers(answers))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionBlock));