export function fromOrDefault(key, defaultValue) {
    const value = localStorage.getItem(key);
    if (!exists(value)) {
        return defaultValue;
    }
    try {
        return JSON.parse(value);
    } catch (e) {
        console.error(`Storage found a non valid value for key [${key}], value=[${value}]`);
        return defaultValue;
    }
}

export function store(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export function remove(key) {
    localStorage.removeItem(key);
}

export function clear() {
    localStorage.clear();
}
/**
 * Returns true if the object is not undefined, not null and has properties.
 */
export function exists(obj) {
    return (!(obj === undefined) && !(obj === null) && (Object.keys(obj).length > 0));
}