import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../assets/images/logo.svg";
import { setPreviousQuestion, setActiveQuestionId, setAnimate } from "../actions/questions";
import { fromOrDefault } from "../services/Storage";
import { USER_ANSWERS_KEY } from "../services/Constants";

function ListItem({id, index, activeIndex}) {
  if (index < activeIndex) {
    return (
      <div className="step-item passed">
          {id}
      </div>
    );
  }
  if (index === activeIndex) {
    return (
      <div className="step-item active">
          {id}
      </div>
    );
  }
  if (index > activeIndex) {
    return (
      <div className="step-item">
          {id}
      </div>
    );
  }
}

class Header extends React.Component {
  componentDidUpdate(prevProps) {
    // handel browser back button
    const userAnswers = fromOrDefault(USER_ANSWERS_KEY, false);
    const prevPath = prevProps.location.pathname.substring(prevProps.location.pathname.lastIndexOf("/") + 1);
    const currPath =  this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf("/") + 1);
    if (prevPath !== currPath) {
      this.props.setAnimate(true)
      setTimeout(() => {
        this.props.setAnimate(false);
      }, 700);
    }
    if (+prevPath > +currPath && !!userAnswers) {
      this.props.setActiveQuestionId(+currPath);
      this.props.setPreviousQuestion(+currPath);
    }
  }

  pervQuestion() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="header">
        <Link to="/" className="logo">
          <img src={logo} alt="EcommerceMatch" width="43" height="34" />
        </Link>
        {!!this.props.hasSteps && !!this.props.questions && this.props.questions.length > 0 && !!this.props.activeQuestionId && (
          <div className="steps">
            {this.props.activeQuestionId > 1 && (
              <button className="btn btn-left"
              onClick={() => this.pervQuestion()}
              disabled={this.props.activeQuestionId === 1 || this.props.isAnimating}
            >
              <span className="icon-arrow-sx"></span>
            </button>
            )}
            {this.props.questions.map((item, i) => (
              <ListItem key={i} id={item.id} index={i +1} activeIndex={this.props.activeQuestionId} />
            ))}
            <div className="step-mob">
              {this.props.activeQuestionId} <span>/ {this.props.questions.length}</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  questions: state.questions.questions,
  activeQuestionId: state.questions.activeQuestionId,
  isAnimating: state.questions.isAnimating,
});

const mapDispatchToProps = (dispatch) => ({
  setPreviousQuestion: (id)  => dispatch(setPreviousQuestion(id)),
  setActiveQuestionId: (questionId) => dispatch(setActiveQuestionId(questionId)),
  setAnimate: (bool) => dispatch(setAnimate(bool)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));