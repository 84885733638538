import React from "react";
import TagManager from 'react-gtm-module';
import AppRouter from "../routers/AppRouter";
import Footer from "../components/Footer";

// Initialize googletagmanager
const tagManagerArgs = {
  gtmId: 'GTM-PQFPVMJ'
};

TagManager.initialize(tagManagerArgs);

export default () => (
    <main className="App">
      <div>
        <AppRouter />
      </div>
      <Footer />
    </main>
  );