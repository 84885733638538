import { getQuiz } from "../services/ApiRequests";

export const getAllQuestions = () => {
    return async (dispatch) => {
        dispatch(getAllQuestionsStarted());
        getQuiz().then(questions => {
            dispatch(getAllQuestionsSuccess(questions));
        }).catch (err => {
            dispatch(getAllQuestionsFailure(err));
        });
    }
};
  
const getAllQuestionsSuccess = questions => ({
    type: 'GET_ALL_QUESTIONS_SUCESS',
    questions
});
  
const getAllQuestionsStarted = () => ({
    type: 'GET_ALL_QUESTIONS_STARDED'
});
  
const getAllQuestionsFailure = error => ({
    type: 'GET_ALL_QUESTIONS_ERROR',    
    error
});

// export const getQuestion = (id) => {
//     return (dispatch) => {
//         dispatch(getSingleQuestionStarted());
//         getSingleQuestion(id).then(question => {
//             dispatch(getSingleQuestionSuccess(question));
//         }).catch (err => {
//             dispatch(getSingleQuestionFailure(err));
//         });
//     }
// }

// const getSingleQuestionSuccess = question => ({
//     type: 'GET_SINGLE_QUESTION_SUCESS',
//     question
// });
  
// const getSingleQuestionStarted = (question) => ({
//     type: 'GET_SINGLE_QUESTION_STARDED'
// });
  
// const getSingleQuestionFailure = error => ({
//     type: 'GET_SINGLE_QUESTION_ERROR',    
//     error
// });

export const setActiveQuestionId = (questionId) => ({
    type: 'SET_ACTIVE_QUESTION_ID',
    questionId
});

export const setUserAnswer = (answer, id) => ({
    type: 'SET_USER_ANSWER',
    answer,
    id
});

export const setPreviousQuestion = (id) => ({
    type: 'SET_PREVIOUS_QUESTION',
    id
});

export const setBestPlatform = (bestPlatform, key) => ({
    type: 'SET_BEST_PLATFORM',
    key,
    bestPlatform
});

export const setPriceRange = (range) => ({
    type: 'SET_PRICE_RANGE',
    range
});

export const reinitUserAnswers = (answers) => ({
    type: 'REINIT_USER_ANSWERS',
    answers
});

export const setAnimate = (bool) => ({
    type: 'SET_ANIMATE_STATE',
    bool
});

export const clearState = () => ({
    type: 'CLEAR_STATE',
});

