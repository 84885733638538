import { op } from "../services/bigjs";
import { store, remove } from "../services/Storage";
import { USER_ANSWERS_KEY, BEST_PLATFORM_KEY, PRICE_RANGE_KEY } from "../services/Constants";

const questionsReducerDefaultState = {
    loadingQestions: false,
    // loadingQestion: false,
    questions: [],
    // question: {},
    activeQuestionId: null,
    userAnswers: {},
    error: null,
    isAnimating: false,
    totalScore: {
        magento: 0,
        woocommerce: 0,
        shopify: 0,
        prestashop: 0,
        custom: 0
    },
    totalPrice: {
        magento: "0",
        woocommerce: "0",
        shopify: "0",
        prestashop: "0",
        custom: "0"
    },
    bestPlatforms: {},
    priceRange: ''
};

export default (state = questionsReducerDefaultState, action) => {
    switch (action.type) {
        case 'GET_ALL_QUESTIONS_STARDED' : 
            return {
                ...state,
                loadingQestions: true
            }
        case 'GET_ALL_QUESTIONS_SUCESS' : 
            return {
                ...state,
                loadingQestions: false,
                error: null,
                questions: action.questions
            }
        case 'GET_ALL_QUESTIONS_ERROR' : 
            return {
                ...state,
                loadingQestions: false,
                error: action.error,
            }
        // case 'GET_SINGLE_QUESTION_STARDED' : 
        //     return {
        //         ...state,
        //         loadingQestion: true
        //     };
        // case 'GET_SINGLE_QUESTION_SUCESS' : 
        //     return {
        //         ...state,
        //         loadingQestion: false,
        //         error: null,
        //         question: action.question
        //     };
        // case 'GET_SINGLE_QUESTION_ERROR' : 
        //     return {
        //         ...state,
        //         loadingQestion: false,
        //         error: action.error,
        //     };
        case 'SET_ACTIVE_QUESTION_ID' : 
            return {
                ...state,
                activeQuestionId: action.questionId
            };
        case 'SET_USER_ANSWER' : 
            store(USER_ANSWERS_KEY,
                {
                    userAnswers: {
                        ...state.userAnswers,
                        [action.id]: {
                            id: action.answer.id,
                            name: action.answer.name,
                            icon: action.answer.icon,
                            position : action.answer.position,
                            ratings: action.answer.ratings 
                        }
                    },
                    totalScore: {
                        magento: state.totalScore.magento + action.answer.ratings.magento.score,
                        woocommerce: state.totalScore.woocommerce + action.answer.ratings.woocommerce.score,
                        shopify: state.totalScore.shopify + action.answer.ratings.shopify.score,
                        prestashop: state.totalScore.prestashop + action.answer.ratings.prestashop.score,
                        custom: state.totalScore.magento + action.answer.ratings.custom.score
                    },
                    totalPrice: {
                        magento: op(state.totalPrice.magento).add(action.answer.ratings.magento.price).toString(),
                        woocommerce: op(state.totalPrice.woocommerce).add(action.answer.ratings.woocommerce.price).toString(),
                        shopify: op(state.totalPrice.shopify).add(action.answer.ratings.shopify.price).toString(),
                        prestashop: op(state.totalPrice.prestashop).add(action.answer.ratings.prestashop.price).toString(),
                        custom: op(state.totalPrice.custom).add(action.answer.ratings.custom.price).toString()
                    }
                }
            );
            return {
                ...state,
                userAnswers: {
                    ...state.userAnswers,
                    [action.id]: {
                        id: action.answer.id,
                        name: action.answer.name,
                        icon: action.answer.icon,
                        position : action.answer.position,
                        ratings: action.answer.ratings 
                    }
                },
                totalScore: {
                    magento: state.totalScore.magento + action.answer.ratings.magento.score,
                    woocommerce: state.totalScore.woocommerce + action.answer.ratings.woocommerce.score,
                    shopify: state.totalScore.shopify + action.answer.ratings.shopify.score,
                    prestashop: state.totalScore.prestashop + action.answer.ratings.prestashop.score,
                    custom: state.totalScore.magento + action.answer.ratings.custom.score
                },
                totalPrice: {
                    magento: op(state.totalPrice.magento).add(action.answer.ratings.magento.price).toString(),
                    woocommerce: op(state.totalPrice.woocommerce).add(action.answer.ratings.woocommerce.price).toString(),
                    shopify: op(state.totalPrice.shopify).add(action.answer.ratings.shopify.price).toString(),
                    prestashop: op(state.totalPrice.prestashop).add(action.answer.ratings.prestashop.price).toString(),
                    custom: op(state.totalPrice.custom).add(action.answer.ratings.custom.price).toString()
                }
            }
        case 'REINIT_USER_ANSWERS' : 
            return {
                ...state,
                userAnswers: action.answers.userAnswers,
                totalScore: action.answers.totalScore,
                totalPrice: action.answers.totalPrice
            }
        case 'SET_PREVIOUS_QUESTION' :
            const newAnswers = {...state.userAnswers};
            delete newAnswers[action.id];
            store(USER_ANSWERS_KEY,
                {
                    userAnswers: newAnswers,
                    totalScore: {
                        magento: state.totalScore.magento - state.userAnswers[action.id].ratings.magento.score,
                        woocommerce: state.totalScore.woocommerce - state.userAnswers[action.id].ratings.woocommerce.score,
                        shopify: state.totalScore.shopify - state.userAnswers[action.id].ratings.shopify.score,
                        prestashop: state.totalScore.prestashop - state.userAnswers[action.id].ratings.prestashop.score,
                        custom: state.totalScore.magento - state.userAnswers[action.id].ratings.custom.score
                    },
                    totalPrice: {
                        magento: op(state.totalPrice.magento).sub(state.userAnswers[action.id].ratings.magento.price).toString(),
                        woocommerce: op(state.totalPrice.woocommerce).sub(state.userAnswers[action.id].ratings.woocommerce.price).toString(),
                        shopify: op(state.totalPrice.shopify).sub(state.userAnswers[action.id].ratings.shopify.price).toString(),
                        prestashop: op(state.totalPrice.prestashop).sub(state.userAnswers[action.id].ratings.prestashop.price).toString(),
                        custom: op(state.totalPrice.custom).sub(state.userAnswers[action.id].ratings.custom.price).toString()
                    }
                }
            );
            return {
                ...state,
                userAnswers: newAnswers,
                totalScore: {
                    magento: state.totalScore.magento - state.userAnswers[action.id].ratings.magento.score,
                    woocommerce: state.totalScore.woocommerce - state.userAnswers[action.id].ratings.woocommerce.score,
                    shopify: state.totalScore.shopify - state.userAnswers[action.id].ratings.shopify.score,
                    prestashop: state.totalScore.prestashop - state.userAnswers[action.id].ratings.prestashop.score,
                    custom: state.totalScore.magento - state.userAnswers[action.id].ratings.custom.score
                },
                totalPrice: {
                    magento: op(state.totalPrice.magento).sub(state.userAnswers[action.id].ratings.magento.price).toString(),
                    woocommerce: op(state.totalPrice.woocommerce).sub(state.userAnswers[action.id].ratings.woocommerce.price).toString(),
                    shopify: op(state.totalPrice.shopify).sub(state.userAnswers[action.id].ratings.shopify.price).toString(),
                    prestashop: op(state.totalPrice.prestashop).sub(state.userAnswers[action.id].ratings.prestashop.price).toString(),
                    custom: op(state.totalPrice.custom).sub(state.userAnswers[action.id].ratings.custom.price).toString()
                }
            }
            case 'SET_BEST_PLATFORM' : 
                store(BEST_PLATFORM_KEY,
                    {
                        bestPlatforms: {
                            ...state.bestPlatforms,
                            [action.key]: action.bestPlatform
                        }
                    }
                );
                return {
                    ...state,
                    bestPlatforms: {
                        ...state.bestPlatforms,
                        [action.key]: action.bestPlatform
                    }
                }
            case 'SET_PRICE_RANGE' :
                store(PRICE_RANGE_KEY, action.range);
                return {
                    ...state,
                    priceRange: action.range
                }
            case 'SET_ANIMATE_STATE' :
                return {
                    ...state,
                    isAnimating: action.bool
                }
                
            case 'CLEAR_STATE' : 
                remove(USER_ANSWERS_KEY);
                remove(BEST_PLATFORM_KEY);
                remove(PRICE_RANGE_KEY);
                return {
                    ...state,
                    loadingQestions: false,
                    userAnswers: {},
                    error: null,
                    totalScore: {
                        magento: 0,
                        woocommerce: 0,
                        shopify: 0,
                        prestashop: 0,
                        custom: 0
                    },
                    totalPrice: {
                        magento: "0",
                        woocommerce: "0",
                        shopify: "0",
                        prestashop: "0",
                        custom: "0"
                    },
                    bestPlatforms: {},
                    priceRange: ''
                }
                
        default :
            return state;
    }
}