import 'core-js/stable';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import "./styles/main.scss";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";

const store = configureStore();
const jsx = (
    <Provider store={store}>
        <App />
    </Provider>
)
ReactDOM.render(jsx, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
